import React from "react"
import { PageProps, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import MetaData from "../components/seo/MetaData"
import OrganizationStructuredData from "../components/seo/OrganizationStructuredData"
import Container from "../components/layout/Container"
import SmallContainer from "../components/layout/SmallContainer"
import CompetitionImage from "../images/vegetar-competition.png"

interface Props extends PageProps {
  location: any
}

const Konkurrence: React.FunctionComponent<Props> = ({ location }) => {
  const { site } = useStaticQuery<any>(
    graphql`
      query metaQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Layout>
      <MetaData
        title="Konkurrence - Vind præmier"
        thumbnailUrl={site.siteMetadata.siteUrl + CompetitionImage}
        description="Her på siden kan du deltage i vores aktive konkurrencer. På denne måde kan du vinde gode præmier, som er relateret til livet som vegetar."
        pageUrl={location.pathname}
        imageAlt="Vegetar - Konkurrence"
      />
      <OrganizationStructuredData />
      <div className="bg-white">
        <Container className="pt-4 text-center">
          <h1>Deltag i konkurrencen om at vinde bogen "100 kødfrie dage"!</h1>
          <hr className="w-48 text-center mx-auto mb-2 border-2" />
        </Container>
        <SmallContainer className="pt-4 mb-16">
          <img
            src={CompetitionImage}
            alt="Vegetar - Konkurrence"
            className="rounded mt-4 mb-4"
          />
          <p className="text-base">
            Her kan du deltage i vores konkurrence, hvor du kan vinde bogen "100
            kødfrie dage". Konkurrence løber frem til d. 14. marts 2022. Du skal
            bare klikke på knappen herunder.
          </p>
          <div data-vl-widget="popupTrigger"></div>
        </SmallContainer>
      </div>
    </Layout>
  )
}

export default Konkurrence
